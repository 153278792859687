







































import Vue from 'vue';
import { mapGetters } from 'vuex';
import ThemeModel from '@/dtos/theme';
export default Vue.extend({
  name: 'PollResults',
  props: {
    choices: {
      type: Array,
      default: () => [],
      required: true,
    },
    pollId: {
      type: String,
      default: '',
      required: true,
    },
    theme: {
      type: Object,
      required: false,
      default: () => ThemeModel.defaultTheme(),
    },
    embedded: {
      type: Boolean,
      required: false,
      default: false,
    },
    invertColors: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getChoicesAnswers: 'presenter/getChoicesAnswers',
    }),
    pollAnswer(): any {
      if (this.pollId in this.getChoicesAnswers) {
        return this.getChoicesAnswers[this.pollId];
      }
      return {};
    },
  },
});
